.Container {
  padding: 40px 72px;
}

.Card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;
  border-radius: 12px;
  border: 1px solid #d8d8d8;
}

.Header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.HeaderTitle {
  color: var(--Gray-800, #333);

  font-size: var(--font-size-XLarge, 20px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--font-multi-line-height-XLarge, 30px); /* 150% */
  letter-spacing: var(--font-letter-spacing-XLarge, -0.4px);
}

.Description {
  color: var(--dop-color-text-level3, #999);

  font-size: var(--font-size-Small, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--font-multi-line-height-Small, 21px); /* 150% */
  letter-spacing: var(--font-letter-spacing-Small, -0.28px);
}

.Contents {
  display: flex;
  gap: 8px;
  padding: 12px 0;
  min-height: 450px;
}

.Divider {
  width: 1px;
  height: 100%;
  background-color: #ededed;
}

.TreeContainer {
  width: 250px;
  height: 100%;
  max-height: 450px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.TreeHeader {
  display: flex;
  gap: 8px;
}
.TreeItem {
  display: flex;
  gap: 4px;
  height: 32px;
  align-items: center;
}
.TreeItemButton {
  padding: 3px;
}

.SelectedTreeItem {
  background: var(--color-bg-hover-solid, #ededed);
}

.CreateFolder {
  border: 1px solid var(--color-border-field, #d8d8d8);
}

.EditContainer {
  flex: 1;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.Field {
  display: flex;
  align-items: flex-start;
}

.FieldTitle {
  color: var(--color-text-level1, #363636);
  font-family: var(--font-Family, Pretendard);
  font-size: var(--font-size-Regular, 16px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--font-multi-line-height-Regular, 24px); /* 150% */
  letter-spacing: var(--font-letter-spacing-Regular, -0.32px);
  min-width: 200px;
  padding: 12px 12px 12px 0px;
}

.FolderDescription {
}

.Input {
  flex: 1;
  padding: 0px var(--Space-Medium, 12px);
  margin: 4px 0;
  height: 40px;
  border-radius: var(--Space-Small, 8px);
  border: 1px solid var(--color-border-field, #d8d8d8);
  background: var(--color-bg-base, #fff);
}
.TextareaField {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.Textarea {
  flex: 1;
  border-radius: var(--Space-Small, 8px);
  border: 1px solid var(--color-border-field, #d8d8d8);
  background: var(--color-bg-base, #fff);
  margin: 4px 0;
  overflow-y: scroll;
  padding: 6px 8px;
}

.Counter {
  display: flex;

  flex-direction: column;
  justify-content: flex-end;

  color: var(--color-text-level3, #999);
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: var(--font-Family, Pretendard);
  font-size: var(--font-size-3XSmall, 11px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--font-size-3XSmall, 11px); /* 100% */
  letter-spacing: var(--font-letter-spacing-3XSmall, -0.44px);
}

.DividerRow {
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
}

.RadioGroup {
  display: flex;
  gap: 24px;
  align-items: center;
}

.Radio {
  display: flex;
  align-items: center;
  gap: 8px;
}

.Buttons {
  width: 100%;
  display: flex;
  gap: 8px;
  padding: 16px 0;
  justify-content: center;
}
